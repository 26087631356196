import { inject }                     from 'aurelia-framework';
import { AggMixturesRepository }      from 'modules/aggregates/lab/services/mixtures-repository';
import { TracksRepository }           from 'modules/aggregates/lab/services/tracks-repository';
import { ConstructionSchema }         from 'modules/aggregates/lab/tab-schemas/construction-schema';
import { OEDatasRepository }          from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { FieldsRepository }           from 'modules/management/dynamic-fields/association-sectors/services/fields-repository';
import { Sector }                     from 'modules/management/models/sector';

@inject(LotInterventionsRepository, OEDatasRepository, TracksRepository, FieldsRepository, AggMixturesRepository)
export class WorkControlSchema extends ConstructionSchema {

    schemaSlug = 'work_control';

    /**
     *
     * @param lotInterventionsRepository
     * @param oeDatasRepository
     * @param tracksRepository
     * @param fieldsRepository
     * @param aggMixturesRepository
     */
    constructor(lotInterventionsRepository, oeDatasRepository, tracksRepository, fieldsRepository, aggMixturesRepository) {
        super(lotInterventionsRepository, oeDatasRepository, tracksRepository);
        this.fieldsRepository      = fieldsRepository;
        this.aggMixturesRepository = aggMixturesRepository;
    }

    /**
     * Returns form schema for "Work Control" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    async schema(viewModel, readonly = false) {

        // Initialize Construction fields
        await super.schema(viewModel, readonly);

        const LAB_WORK_CONTROL = Sector.LAB_WORK_CONTROL;
        const LAB_USE_IN       = Sector.LAB_USE_IN;

        const fieldsObserver = (source, target, criteria) => {
            if (!source.input.instance) return;

            let newValue = source.input.instance.model.value;

            // convert target to an array if it's not already
            const targets = Array.isArray(target) ? target : [target];

            targets.forEach((t) => {
                if (t.input.instance) {
                    t.input.instance.model.value = null;

                    if (newValue) {
                        t.input.attributes.disabled = false;

                        t.input.remoteSource = () => getFields(t.name, source.name, newValue, criteria);

                        t.input.instance.fetchData();
                    } else {
                        t.input.attributes.disabled = true;
                    }
                }

            });
        };

        const getFields = (field, source, value, criteria) => {
            return this.fieldsRepository.getFields(
                viewModel.sectorId,
                field,
                {
                    [source]: value,
                    ...criteria,
                },
            );
        };

        this.wc_sample_collection_id = {
            type:         'select2',
            key:          'wc_sample_collection_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.sample-collection',
            remoteSource: () => this.fieldsRepository.getFields(viewModel.sectorId,
                'sample-collection',
                { slug: [LAB_WORK_CONTROL, LAB_USE_IN] },
            ),
            observers:    [
                () => fieldsObserver(
                    {
                        input: this.wc_sample_collection_id,
                        name:  'sample_collection_id',
                    },
                    {
                        input: this.part_zone_application_id,
                        name:  'part-zone-application',
                    },
                    { slug: [LAB_WORK_CONTROL, LAB_USE_IN] }),
            ],
            required:     false,
            size:         6,
            attributes:   {
                disabled: readonly,
            },
        };

        this.part_zone_application_id = {
            type:         'select2',
            key:          'part_zone_application_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.part-zone-application',
            remoteSource: () => (viewModel.model.wc_sample_collection_id)
                ? getFields(
                    'part-zone-application',
                    'sample_collection_id',
                    viewModel.model.wc_sample_collection_id,
                    { slug: [LAB_WORK_CONTROL, LAB_USE_IN] })
                : Promise.resolve([]),
            observers:    [
                () => fieldsObserver(
                    {
                        input: this.part_zone_application_id,
                        name:  'part_zone_application_id',
                    },
                    [
                        {
                            input: this.usage_id,
                            name:  'usage',
                        },
                        {
                            input: this.layer_id,
                            name:  'layer',
                        },
                    ],
                    { sample_collection_id: this.wc_sample_collection_id.instance.model.value }),
            ],
            required:     false,
            size:         6,
            attributes:   {
                disabled: viewModel.model.wc_sample_collection_id === null,
            },
        };

        this.usage_id = {
            type:         'select2',
            key:          'usage_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.usage',
            remoteSource: () => (viewModel.model.part_zone_application_id)
                ? getFields(
                    'usage',
                    'part_zone_application_id',
                    viewModel.model.part_zone_application_id,
                    { sample_collection_id: viewModel.model.sample_collection_id })
                : Promise.resolve([]),
            required:     false,
            size:         6,
            attributes:   {
                disabled: viewModel.model.part_zone_application_id === null,
            },
        };

        this.layer_id = {
            type:         'select2',
            key:          'layer_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.layer',
            remoteSource: () => (viewModel.model.part_zone_application_id)
                ? getFields(
                    'layer',
                    'part_zone_application_id',
                    viewModel.model.part_zone_application_id,
                    { sample_collection_id: viewModel.model.sample_collection_id })
                : Promise.resolve([]),
            required:     false,
            size:         6,
            attributes:   {
                disabled: viewModel.model.part_zone_application_id === null,
            },
        };

        this.dimensions = {
            type:      'field-group',
            key:       'dimensions',
            label:     'form.field.minimum-maximum-dimensions(mm)',
            required:  false,
            size:      4,
            innerSize: 6,
            fields:    [
                {
                    type:       'number',
                    key:        'min_dimension',
                    showLabel:  false,
                    required:   false,
                    size:       '45p',
                    attributes: {
                        readonly: readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '/',
                    size:  '30p',
                },
                {
                    type:       'number',
                    key:        'max_dimension',
                    showLabel:  false,
                    required:   false,
                    size:       '45p',
                    attributes: {
                        readonly: readonly,
                    },
                },
            ],
        };

        this.depth_quota = {
            type:       'number',
            key:        'depth_quota',
            label:      'form.field.depth-quota(m)',
            required:   false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.axis_distance = {
            type:       'number',
            key:        'axis_distance',
            label:      'form.field.axis-distance(m)',
            required:   false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.layer_thickness = {
            type:       'number',
            key:        'layer_thickness',
            label:      'form.field.layer-thickness(m)',
            required:   false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.mixture_id = {
            type:         'select2',
            key:          'mixture_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.mixture',
            remoteSource: () => this.aggMixturesRepository.all(),
            required:     false,
            size:         6,
            attributes:   {
                disabled: readonly,
            },
        };

        this.cement = {
            type:       'text',
            key:        'cement',
            label:      'form.field.cement',
            required:   false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.cement_type = {
            type:       'text',
            key:        'cement_type',
            label:      'form.field.cement-type',
            required:   false,
            size:       6,
            attributes: {
                readonly: readonly,
            },
        };

        this.reference_sample_id = {
            type:         'select2',
            key:          'reference_sample_id',
            label:        'form.field.reference-aggregate-sample-to-treat-num',
            remoteSource: () => viewModel.repository.referenceSamples(viewModel.model.id),
            hidden:       viewModel.category !== 'treated',
            required:     false,
            size:         6,
            attributes:   {
                disabled: readonly,
            },
        };

        return [
            [this.wc_sample_collection_id, this.part_zone_application_id],
            [this.usage_id, this.layer_id],
            [this.dimensions],
            [this.lot_construction_id, this.branch_id, this.edge_id],
            [this.oe_data_id, this.collection_pk, this.applied_pk],
            [this.number_of_tracks, this.axis],
            [this.track_id, this.depth_quota],
            [this.axis_distance, this.layer_thickness],
            ...(viewModel.category === 'treated' ? [
                [this.mixture_id],
                [this.cement, this.cement_type],
                [this.reference_sample_id],
            ] : []),
        ];
    }
}
