import { inject }                          from 'aurelia-framework';
import { SelectedBulletinFilesRepository } from 'modules/aggregates/selected-bulletins/services/files-repository';
import { ProcessStatus }                   from 'modules/core/models/process-status';
import { AppContainer }                    from 'resources/services/app-container';
import { Downloader }                      from 'resources/services/downloader';

@inject(AppContainer, SelectedBulletinFilesRepository, Downloader)
export class SelectedBulletinIsTestUploadedCustomListingCell {

    processing;
    icon;

    /**
     * Constructor.
     *
     * @param appContainer
     * @param filesRepository
     * @param downloader
     */
    constructor(appContainer, filesRepository, downloader) {
        this.appContainer    = appContainer;
        this.filesRepository = filesRepository;
        this.downloader      = downloader;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column = model.column;
        this.row    = model.row;

        const statusId = this.row.process_status;

        this.icon  = ProcessStatus.getIconByStatusId(statusId);
        this.title = ProcessStatus.getTooltipByStatusId(statusId);

        this.processing = statusId === ProcessStatus.IN_PROGRESS_WRITING_HEADERS_FOOTERS || statusId === ProcessStatus.IN_PROGRESS_READING;
    }

    /**
     * Downloads the file
     */
    downloadFile() {
        const fileName = this.row.uploaded_file.display_name.replace(/\.[^/.]+$/, '');

        this.filesRepository
            .download(this.row.uploaded_file.id)
            .then((blob) => this.downloader.download(blob, fileName, this.row.uploaded_file.extension));
    }
}
